<template>
  <div class="dashboard text-white" v-if="schedules">
    <Header />
    <div class="px-4 my-4">
      <div
        class="bg-lColorBGGray text-nColorLightGray rounded-xl px-4 py-1 mb-2 text-lg flex justify-between items-center"
      >
        <div class="font-ZuumeSemiBold text-nColorYellow text-3xl">
          {{ businessName }}
        </div>
        <div>Total Users: {{ schedules.total_customers }}</div>
      </div>
      <div class="border border-nColorYellow rounded-3xl">
        <div class="flex">
          <div
            id="active"
            :class="{
              'bg-transparent text-white': !active,
              'bg-nColorOrange text-black': active,
            }"
            class="rounded-3xl w-1/2 py-1 font-InterMedium font-bold text-center m-0.5"
            @click.prevent="showActiveGames()"
          >
            Active Games
          </div>
          <div
            id="active"
            :class="{
              'bg-transparent text-white': !completed,
              'bg-nColorOrange text-black': completed,
            }"
            class="rounded-3xl w-1/2 py-1 font-InterMedium font-bold text-center m-0.5"
            @click.prevent="showCompletedGames()"
          >
            Completed Games
          </div>
        </div>
      </div>
      <div>
        <div v-if="active">
          <div v-if="schedules.active.length === 0">
            <div class="w-full p-10 text-nColorSubTextGray text-center">
              You have no active schedules
            </div>
          </div>
          <div v-else>
            <div v-for="game in schedules.active" :key="game">
              <router-link
                :to="{
                  name: 'Stats',
                  params: {
                    business: business,
                    schedule_id: game.schedule_id,
                  },
                }"
                class="text-gray-600 text-xs md:text-xl font-medium"
              >
                <div
                  class="border border-nColorYellow rounded-2xl shadow-orange-right p-2 my-3 flex gap-2"
                >
                  <div>
                    <img :src="game.game.icon" width="50" height="50" />
                  </div>
                  <div class="grid content-center">
                    <div class="font-ZuumeSemiBold text-nColorYellow text-3xl">
                      {{ game.game.name }}
                    </div>
                    <div
                      class="font-InterRegular text-nColorSubTextGray text-xs"
                    >
                      Ending at {{ game.ending }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="completed">
          <div v-if="schedules.completed.length === 0">
            <div class="w-full p-10 text-nColorSubTextGray text-center">
              You have no completed schedules
            </div>
          </div>
          <div v-else>
            <div v-for="game in schedules.completed" :key="game">
              <router-link
                :to="{
                  name: 'Stats',
                  params: {
                    business: business,
                    schedule_id: game.schedule_id,
                  },
                }"
                class="text-gray-600 text-xs md:text-xl font-medium"
              >
                <div
                  class="border border-nColorYellow rounded-2xl shadow-orange-right p-2 my-3 flex gap-2"
                >
                  <div>
                    <img :src="game.game.icon" width="50" height="50" />
                  </div>
                  <div class="grid content-center">
                    <div class="font-ZuumeSemiBold text-nColorYellow text-3xl">
                      {{ game.game.name }}
                    </div>
                    <div
                      class="font-InterRegular text-nColorSubTextGray text-xs"
                    >
                      Completed at {{ game.ended }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/Elements/Header.vue";

export default {
  name: "Dashboard",
  components: { Header },
  data() {
    return {
      schedules: null,
      active: true,
      completed: false,
      business: this.$route.params.business,
      businessName: this.$store.getters.businessName,
    };
  },
  created() {
    ApiService.get(apiResource.getBusinessSchedules, {
      business_slug: this.$route.params.business,
    }).then((data) => {
      this.schedules = data.data.data;
      if (
        this.schedules.completed.length != 0 &&
        this.schedules.active.length === 0
      ) {
        this.showCompletedGames();
      }
    });
  },
  mounted() {},
  methods: {
    showActiveGames() {
      this.active = true;
      this.completed = false;
    },
    showCompletedGames() {
      this.active = false;
      this.completed = true;
    },
  },
};
</script>

<style scoped>
.dashboard {
  min-height: 100dvh;
}
</style>
