<template>
  <div>
    <div class="flex justify-between px-4 mt-6">
      <div>
        <router-link :to="{ name: 'Dashboard' }">
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            class="w-2/6"
          />
        </router-link>
      </div>
      <div v-if="$route.name === 'Dashboard'">
        <button
          @click.prevent="logout()"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-4 py-0.5 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Logout
        </button>
      </div>
      <div v-if="$route.name === 'Stats'">
        <button
          @click.prevent="back()"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-4 py-0.5 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  components: {},
  methods: {
    logout() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    back() {
      this.$router.push({
        name: "Dashboard",
        params: {
          business: this.$route.params.business,
        },
      });
    },
  },
};
</script>

<style scoped></style>
